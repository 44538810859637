//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      localValue: this.parseUuidListToCategoryObjectList(this.value)
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories/getterCategories'
    })
  },
  watch: {
    localValue: {
      deep: true,
      handler (newVal) {
        this.$emit('change', this.parseCategoryObjectListToUuidList(newVal))
      }
    },
    value (newVal) {
      if (!Array.isArray(newVal) && !Array.isArray(this.localValue)) {
        return
      }
      if ((Array.isArray(newVal) && !Array.isArray(this.localValue)) || (!Array.isArray(newVal) && Array.isArray(this.localValue))) {
        this.localValue = this.parseUuidListToCategoryObjectList(newVal)
        return
      }
      if (newVal.length !== this.localValue.length) {
        this.localValue = this.parseUuidListToCategoryObjectList(newVal)
        return
      }
      if (!this.localValue.every(category => newVal.includes(category.uuid))) {
        this.localValue = this.parseUuidListToCategoryObjectList(newVal)
      }
    }
  },
  methods: {
    parseUuidListToCategoryObjectList (list) {
      if (!list) { return null }
      // console.log(this.$store.getters['categories/getterCategories'])
      return list.map((uuid) => {
        return (this.$store.getters['categories/getterCategories'] || []).find(category => category.uuid === uuid)
      }).filter(Boolean)
    },
    parseCategoryObjectListToUuidList (list) {
      if (!list) { return null }
      return list.map(category => category.uuid).filter(Boolean)
    }
  }
}
